<template>
  <v-footer
    id="core-footer"
    app
    height="82"
  >
    <div class="footer-items">
      <span v-for="link in links" :key="link.name">
        <a target="_blank" :href="link.Link" class="tertiary--text footer-links">{{ link.name }}</a>
      </span>
    </div>
    <v-spacer/>
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      <a href="https://www.compado.com/" target="_blank">Compado</a>
    </span>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',

  data: () => ({
    links: [
      { name: 'Documentation', Link: 'https://wandome.atlassian.net/wiki/spaces/TT/pages/1835925756/Adtech+UI' },
      { name: 'CMS', Link: 'https://api-domain-compado.com/admin' },
      { name: 'Wandoso', Link: 'http://admin.wandoso.com' },
    ],
  }),
}
</script>

<style>
#core-footer {
  z-index: 0;
}

.v-footer .footer-links {
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  padding: 15px;
  line-height: 1.8;
}
</style>
